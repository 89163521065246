import { useState, createRef } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import Collapse from "components/Collapse";
import ".././../utils/sweetalert.css"
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import FileItem from "./FileItem";
import CreateDirectory from "./CreateDIrectory";
import {
  deleteFileRequest,
  deleteFilesRequest,
  createDirectoryRequest,
  renameFileRequest,
} from "api/filemanager";
import PreviewFile from "./PreviewFile";
import DirectoryItem from "./DirectoryItem";
import useFileManager from "hooks/useFileManager";

export default function Files({
  files,
  openDir,
  setState,
  state,
  fileable_type,
  fileable_id,
}) {
  const previewRef = createRef();
  const createDirectoryRef = createRef();
  const [selectedFiles, selectFile] = useState([]);
  const { getFiles} = useFileManager();

  const selectFileHandler = (fileUuid) => {
    if (!selectedFiles.includes(fileUuid)) {
      selectFile([...selectedFiles, fileUuid]);
    } else {
      selectFile((files) => files.filter((file) => file !== fileUuid));
    }
  };

  const deleteFileHandler = (fileUuid = null) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      customClass: {
        title: "swal-text",
        popup: "swal-text"
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        if (fileUuid) {
          deleteFileRequest(fileUuid).then(() => {
            Swal.fire({
              toast: true,
              timer: 3000,
              position: "top-right",
              title: 'File Deleted Successfully!',
              showConfirmButton: false,
              icon: "success"
            })
            const fileterdFiles = files.filter(
              (file) => file.uuid !== fileUuid
            );
            setState({ files: fileterdFiles });
          });
          return;
        }
        deleteFilesRequest(selectedFiles).then(() => {
          let fileterdFiles = files.filter((file) => {
            return !selectedFiles.includes(file.uuid);
          });
          setState({ files: fileterdFiles });
        });
      }
    });
  };

  const createDirectoryHandler = (label) => {
    createDirectoryRequest({
      label,
      fileable_type,
      fileable_id,
      parent_id: state.parent_id,
    }).then((response) => {
      setState({ files: [response.data, ...files] });
    });
  };

  const renameFileHandler = (label, fileUuid) => {
    renameFileRequest(label, fileUuid).then((response) => {
      const filesArray = [...state.files];
      let fileIndex = filesArray.findIndex(
        (file) => file.uuid === response.data.uuid
      );
      filesArray[fileIndex] = response.data;
      setState({ files: filesArray });
    });
  };

  const downloadFileHandler = (fileUuid) => {
    window.open(
      `${process.env.REACT_APP_API_DOMAIN}/api/v1/file-download/${fileUuid}`
    );
  };
  /**
   * TODO: Refactor this function
   */
  const downloadFilesHandler = () => {
    // eslint-disable-next-line array-callback-return
    selectedFiles.map((selectedFile) => {
      const file = files.find((file) => file.uuid === selectedFile);
      if (file.type === "directory") {
        getFiles({
          fileable_type,
          fileable_id,
          parent_id: file.uuid,
        }).then((response) => {
          if (response.data.files.length > 0) {
            downloadFileHandler(response.data.files[0].uuid).then((downloadResult) => {
              console.log(`File downloaded successfully: ${downloadResult}`);
            });
          }
        });
      }
    });
  };

  return (
    <Box>
      <Box mb={2} display="flex" justifyContent="space-between">
        {/* <Button
          sx={{ mr: 1 }}
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => createDirectoryRef.current.open()}
        >
          Create Folder
        </Button> */}
        {selectedFiles.length > 0 && (
          <Box>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => deleteFileHandler()}
            >
              Delete
            </Button>
            <Button
              sx={{ ml: 1 }}
              variant="outlined"
              startIcon={<CloudDownloadIcon />}
              onClick={() => downloadFilesHandler()}
            >
              Download
            </Button>
          </Box>
        )}
      </Box>
      <Collapse title="JOBS">
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left" style={{ width: "50%" }}>
                Name
              </TableCell>
              <TableCell align="right">Last update</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.filter(file => file.type !== "directory" && file.fileable_type === "\\App\\Models\\Job").length > 0 ? (
              files.map((file, index) =>
                file.type !== "directory" && file.fileable_type === "\\App\\Models\\Job" ? (
                  <DirectoryItem
                    index={index}
                    key={file.uuid}
                    isSelected={selectedFiles.includes(file.uuid)}
                    file={file}
                    deleteFile={deleteFileHandler}
                    selectFile={selectFileHandler}
                    downloadFile={downloadFileHandler}
                    renameFile={(fileUuid) => createDirectoryRef.current.open(fileUuid)}
                    previewFile={(index) => previewRef.current.display(index)}
                  />
                ) : null // Simply skip rendering anything for directories
              )
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="body1">No files uploaded yet.</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {/* {files.length > 0 &&
              files.map((file, index) =>
                file.type === "directory" ? (
                  <DirectoryItem
                    key={file.uuid}
                    isSelected={selectedFiles.includes(file.uuid)}
                    file={file}
                    selectFile={selectFileHandler}
                    openDir={openDir}
                    renameFile={(fileUuid) =>
                      createDirectoryRef.current.open(fileUuid)
                    }
                    previewFile={(index) => previewRef.current.display(index)}
                  />
                ) : null
              )}
            {files.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="body1">
                      No files uploaded yet.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </Collapse>
      <Collapse title="MEMBERSHIP">
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left" style={{ width: "50%" }}>
                Name
              </TableCell>
              <TableCell align="right">Last update</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.filter(file => file.type !== "directory" && file.fileable_type === "\\App\\Models\\Membership").length > 0 ? (
              files.map((file, index) =>
                file.type !== "directory" && file.fileable_type === "\\App\\Models\\Membership" ? (
                  <FileItem
                    index={index}
                    key={file.uuid}
                    isSelected={selectedFiles.includes(file.uuid)}
                    file={file}
                    deleteFile={deleteFileHandler}
                    selectFile={selectFileHandler}
                    downloadFile={downloadFileHandler}
                    renameFile={(fileUuid) => createDirectoryRef.current.open(fileUuid)}
                    previewFile={(index) => previewRef.current.display(index)}
                  />
                ) : null // Simply skip rendering anything for directories
              )
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="body1">No files uploaded yet.</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Collapse>
      <TableContainer>
      </TableContainer>
      <PreviewFile
        ref={previewRef}
        deleteFile={deleteFileHandler}
        downloadFile={downloadFileHandler}
        files={files}
      />
      <CreateDirectory
        ref={createDirectoryRef}
        createHandler={createDirectoryHandler}
        renameHandler={renameFileHandler}
        files={files}
      />
    </Box>
  );
}
