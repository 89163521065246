import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Tab,
  Button,
  CircularProgress
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useParams } from "react-router-dom";
import useInvoices from "hooks/useInvoices";
import Notes from "components/Notes";
import { invoiceStatusColors } from "utils/statusColors";
import NotFound from "../../../Errors/NotFound";
import MemberInformation from "../components/MemberInformation";
import Invoice from "../components/Invoice";
import Toast from "components/Toast";

const View = () => {
  const [tabValue, setTabValue] = useState(0);
  const [loadingMail, setLoadingMail] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const { viewInvoice, emailInvoice, downloadInvoice } = useInvoices();
  const [state, _setState] = useState({
    loading: true,
    invoice: {},
    notFound: false,
  });

  const setState = (values) => _setState({ ...state, ...values });

  useEffect(() => {
    viewInvoice(id)
      .then((res) => {
        setState({
          loading: false,
          invoice: res.data.invoice,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setState({
            notFound: true,
            loading: false,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state?.notes) {
      setTabValue(1);
    }
  }, [location]);

  if (state.notFound) {
    return <NotFound />;
  }

  if (state.loading) {
    return null;
  }

  const emailInvoiceFun = (id) => {
    setLoadingMail(true)
    emailInvoice(id)
      .then((res) => {
        setLoadingMail(false)
        Toast.fire({
          title: res.data.message,
          icon: "success",
          position:"top-right",
          timer:3000
        });
      })
      .catch((error) => {
       console.log('error',error);
      });
  }

  const downloadInvoiceFun = async (id) => {
    const response = await downloadInvoice(id);
    return response.data;
  };

  const downloadDocument =  async (id) => {
      const blob = await downloadInvoiceFun(id);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `HOC-Invoice-${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      Toast.fire({
        title: "PDF Download  Successfully",
        icon: "success",
      });
    }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Chip
          label={`Status: ${state?.invoice?.status}`}
          style={{
            background: invoiceStatusColors[state?.invoice?.status],
            color: "white",
          }}
        />
        <Typography variant="h5" sx={{ ml: 2 }}>
          {state?.invoice?.description} No. {state?.invoice?.id}
        </Typography>
      </Box>
      <Paper sx={{ p: 2, mt: 2 }} >
        <TabContext value={tabValue}>
          <Box sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          >
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Invoice informations"
                value={0}
              />
              <Tab label="Notes" value={1} />
              {/* <Tab label="Refund" value={2} /> */}
            </TabList>
            <Box>
              <Button
                sx={{
                  ml: 2, backgroundColor: '#FC6736', color: 'white', '&:hover': {
                    backgroundColor: '#FC6736', // Keeps the background color unchanged on hover
                  }
                }}
                variant="contained"
                // startIcon={<AddIcon />}
                onClick={() => emailInvoiceFun(state.invoice.id)}
              >
                Email Invoice
                {loadingMail && (
                  <Box pl={2}>
                    <CircularProgress size={16} />
                  </Box>
                )}
              </Button>
              <Button
                sx={{
                  ml: 2,
                  backgroundColor: '#2196f3',
                  color: 'white', '&:hover': {
                    backgroundColor: '#2196f3', // Keeps the background color unchanged on hover
                  }
                }}
                variant="contained"
                // startIcon={<AddIcon />}
                onClick={() => downloadDocument(state?.invoice?.id)}
              >
                Download Invoice
              </Button>
            </Box>
          </Box>
          <TabPanel value={0}>
            <Grid container spacing={4}>
              <Grid item sx={12} sm={4} md={3}>
                <Box mb={4}>
                  <MemberInformation membership={state?.invoice?.membership} />
                  
                </Box>
              </Grid>
              <Grid item sx={12} sm={8} md={9}>
                <Invoice
                  invoice={state?.invoice}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={1}>
            <Notes
              notableType="Invoices"
              notableId={id}
            />
          </TabPanel>
          <TabPanel>
           
          </TabPanel>
          {/* <TabPanel value={2}>
            <Refund
              notableType="Invoices"
              notableId={id}
            />
          </TabPanel> */}
        </TabContext>
      </Paper>
    </>
  );
};

export default View;














// import React, { useState } from 'react';
// import axios from 'axios';
// import Button from '@mui/material/Button';

// const PdfDownloader = () => {
//   const [loading, setLoading] = useState(false);

//   const downloadPdf = () => {
//     setLoading(true);
//     axios.get('YOUR_API_ENDPOINT', { responseType: 'blob' })
//       .then(response => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', 'file.pdf');
//         document.body.appendChild(link);
//         link.click();
//         setLoading(false);
//       })
//       .catch(error => {
//         console.error('Error downloading PDF:', error);
//         setLoading(false);
//       });
//   };

//   return (
//     <Button variant="contained" onClick={downloadPdf} disabled={loading}>
//       {loading ? 'Downloading...' : 'Download PDF'}
//     </Button>
//   );
// };

// export default PdfDownloader;
