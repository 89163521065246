import { Box, Button, Grid, Typography } from "@mui/material";
import FormField from "components/FormField";
import UpdateIndex from "components/QModal/UpdateIndex";
import React, { useEffect, useState } from "react";
import { updateInvoicedata } from "api/invoices";

const UpdateOpenInvoice = ({ form, handleClose, onSubmitHandler }) => {
  const [values, setValues] = useState(form);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: { ...prevValues[parent], [child]: value },
      }));
    } else {
      setValues((prevValues) => ({ ...prevValues, [name]: value }));
      if (name === "amount") {
        const totalSale = values.inv_total_sale;
        const factorPercent = (value / totalSale) * 100;
        const conBill = values.total - value;
        setValues((prevValues) => ({
          ...prevValues,
          factor_percent: factorPercent,
          con_bill: conBill,
          updated_amount: value,
        }));
      }
    }
  };

  useEffect(() => {
    updateInvoicedata(form.id, values);
  }, [values]);

  useEffect(() => {
    const calculateTotals = () => {
      const materialTotal = Object.keys(values.material).reduce((acc, key) => {
        if (key.startsWith("material_amt_")) {
          return acc + parseFloat(values.material[key]) || 0;
        }
        return acc;
      }, 0);
      const laborTotal = Object.keys(values.labor).reduce((acc, key) => {
        if (key.startsWith("labor_amt_")) {
          return acc + parseFloat(values.labor[key]) || 0;
        }
        return acc;
      }, 0);
      const netSale = materialTotal + laborTotal;
      const taxAmount = netSale * (values.tax / 100);
      const totalSale = netSale + taxAmount;
      const amount = totalSale * (values.factor_percent / 100);
      const conBill = totalSale - amount;
      const factorPercent = (amount / totalSale) * 100;
      setValues((prevValues) => ({
        ...prevValues,
        inv_total_material: materialTotal,
        inv_total_labor: laborTotal,
        inv_net_sale: netSale,
        inv_tax_amount: taxAmount,
        inv_total_sale: totalSale,
        total: totalSale,
        amount: amount,
        con_bill: conBill,
        factor_percent: factorPercent,
      }));
    };
    calculateTotals();
  }, [
    values.material,
    values.job_number,
    values.tax,
    values.labor,
    values.factor_percent,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await onSubmitHandler(values);
  };

  const formattedDate = new Date(values.created_at).toISOString().split("T")[0];

  return (
    <>
      <UpdateIndex
        dialogProps={{
          open: true,
          title: `Update Open Invoices`.toUpperCase(),
          subtitle: "",
        }}
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Box>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="ID"
                  name="id"
                  disabled={values.id}
                  value={values.id}
                />
                <FormField
                  label="Invoice Number"
                  name="hoc_inv_num"
                  disabled={values.id}
                  value={values.hoc_inv_num}
                />
                <FormField
                  label="Contractor Code"
                  name="con"
                  disabled={values.id}
                  value={values.con}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Job Number"
                  name="job_number"
                  type="number"
                  onChange={handleChange}
                  value={values.job_number}
                />
                <FormField
                  label="Member Name"
                  name="mem_name"
                  disabled={values.id}
                  value={values.mem_name}
                />
                <FormField
                  label="Invoice Date"
                  name="created_at"
                  disabled={values.id}
                  value={formattedDate}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Member Address"
                  name="mem_street"
                  onChange={handleChange}
                  value={values.mem_street}
                />
                <FormField
                  label="Remarks"
                  name="remarks"
                  onChange={handleChange}
                  value={values.remarks}
                />
                <FormField
                  label="Contractor Business"
                  name="con_biz"
                  disabled={values.id}
                  value={values.con_biz}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Total Invoice Labor"
                  name="inv_total_labor"
                  disabled={values.id}
                  value={values.inv_total_labor}
                />
                <FormField
                  label="Total Invoice Material"
                  name="inv_total_material"
                  disabled={values.id}
                  value={values.inv_total_material}
                />
                <FormField
                  label="Invoice Net Sale"
                  name="inv_net_sale"
                  disabled={values.id}
                  value={values.inv_net_sale}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Tax %"
                  name="tax"
                  type="number"
                  onChange={handleChange}
                  value={values.tax}
                />
                <FormField
                  label="Total Amount"
                  name="total"
                  type="number"
                  disabled={values.id}
                  onChange={handleChange}
                  value={values.total.toFixed(2)}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Factoring Amount/Rate %"
                  name="factor_percent"
                  type="number"
                  onChange={handleChange}
                  value={Number(values.factor_percent).toFixed(2)}
                />
                <FormField
                  label="Amount"
                  name="amount"
                  type="number"
                  onChange={handleChange}
                  value={Number(values.amount).toFixed(2)}
                />
                <FormField
                  label="Con. Bill"
                  name="con_bill"
                  type="number"
                  disabled={values.id}
                  onChange={handleChange}
                  value={values.con_bill.toFixed(2)}
                />
              </div>
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  borderBottom: "1px solid black",
                  paddingBottom: "5px",
                }}
              >
                {" "}
                {`Materials`.toUpperCase()}
              </Typography>
              {values.material.material_1 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 1"
                    name="material.material_1"
                    onChange={handleChange}
                    value={values.material.material_1}
                  />
                  <FormField
                    label="Material Amount 1"
                    name="material.material_amt_1"
                    type="number"
                    onChange={handleChange}
                    value={values.material.material_amt_1}
                  />
                </div>
              )}
              {values.material.material_2 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 2"
                    name="material.material_2"
                    onChange={handleChange}
                    value={values.material.material_2}
                  />
                  <FormField
                    label="Material Amount 2"
                    name="material.material_amt_2"
                    type="number"
                    onChange={handleChange}
                    value={values.material.material_amt_2}
                  />
                </div>
              )}
              {values.material.material_3 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 3"
                    name="material.material_3"
                    type="number"
                    onChange={handleChange}
                    value={values.material.material_3}
                  />
                  <FormField
                    label="Material Amount 3"
                    name="material.material_amt_3"
                    onChange={handleChange}
                    value={values.material.material_amt_3}
                  />
                </div>
              )}
              {values.material.material_4 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 4"
                    name="material.material_4"
                    onChange={handleChange}
                    value={values.material.material_4}
                  />
                  <FormField
                    label="Material Amount 4"
                    name="material.material_amt_4"
                    type="number"
                    onChange={handleChange}
                    value={values.material.material_amt_4}
                  />
                </div>
              )}
              {values.material.material_5 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 5"
                    name="material.material_5"
                    onChange={handleChange}
                    value={values.material.material_5}
                  />
                  <FormField
                    label="Material Amount 5"
                    name="material.material_amt_5"
                    onChange={handleChange}
                    value={values.material.material_amt_5}
                  />
                </div>
              )}
              {values.material.material_6 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 6"
                    name="material.material_6"
                    onChange={handleChange}
                    value={values.material.material_6}
                  />
                  <FormField
                    label="Material Amount 6"
                    name="material.material_amt_6"
                    onChange={handleChange}
                    value={values.material.material_amt_6}
                  />
                </div>
              )}
              {values.material.material_7 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 7"
                    name="material.material_7"
                    onChange={handleChange}
                    value={values.material.material_7}
                  />
                  <FormField
                    label="Material Amount 7"
                    name="material.material_amt_7"
                    onChange={handleChange}
                    value={values.material.material_amt_7}
                  />
                </div>
              )}
              {values.material.material_8 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 8"
                    name="material.material_8"
                    onChange={handleChange}
                    value={values.material.material_8}
                  />
                  <FormField
                    label="Material Amount 8"
                    name="material.material_amt_8"
                    onChange={handleChange}
                    value={values.material.material_amt_8}
                  />
                </div>
              )}
              {values.material.material_9 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 9"
                    name="material.material_9"
                    onChange={handleChange}
                    value={values.material.material_9}
                  />
                  <FormField
                    label="Material Amount 9"
                    name="material.material_amt_9"
                    onChange={handleChange}
                    value={values.material.material_amt_9}
                  />
                </div>
              )}
              {values.material.material_10 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 10"
                    name="material.material_10"
                    onChange={handleChange}
                    value={values.material.material_10}
                  />
                  <FormField
                    label="Material Amount 10"
                    name="material.material_amt_10"
                    onChange={handleChange}
                    value={values.material.material_amt_10}
                  />
                </div>
              )}
              {values.material.material_11 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 11"
                    name="material.material_11"
                    onChange={handleChange}
                    value={values.material.material_11}
                  />
                  <FormField
                    label="Material Amount 11"
                    name="material_amt_11"
                    onChange={handleChange}
                    value={values.material.material_amt_11}
                  />
                </div>
              )}
              {values.material.material_12 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 12"
                    name="material.material_12"
                    onChange={handleChange}
                    value={values.material.material_12}
                  />
                  <FormField
                    label="Material Amount 12"
                    name="material.material_amt_12"
                    onChange={handleChange}
                    value={values.material.material_amt_12}
                  />
                </div>
              )}
              {values.material.material_13 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 13"
                    name="material.material_13"
                    onChange={handleChange}
                    value={values.material.material_13}
                  />
                  <FormField
                    label="Material Amount 13"
                    name="material.material_amt_13"
                    onChange={handleChange}
                    value={values.material.material_amt_13}
                  />
                </div>
              )}
              {values.material.material_14 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 14"
                    name="material.material_14"
                    onChange={handleChange}
                    value={values.material.material_14}
                  />
                  <FormField
                    label="Material Amount 14"
                    name="material.material_amt_14"
                    onChange={handleChange}
                    value={values.material.material_amt_14}
                  />
                </div>
              )}
              {values.material.material_15 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 15"
                    name="material.material_15"
                    onChange={handleChange}
                    value={values.material.material_15}
                  />
                  <FormField
                    label="Material Amount 15"
                    name="material.material_amt_15"
                    onChange={handleChange}
                    value={values.material.material_amt_15}
                  />
                </div>
              )}
              {values.material.material_16 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 16"
                    name="material.material_16"
                    onChange={handleChange}
                    value={values.material.material_16}
                  />
                  <FormField
                    label="Material Amount 16"
                    name="material.material_amt_16"
                    onChange={handleChange}
                    value={values.material.material_amt_16}
                  />
                </div>
              )}
              {values.material.material_17 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 17"
                    name="material.material_17"
                    onChange={handleChange}
                    value={values.material.material_17}
                  />
                  <FormField
                    label="Material Amount 17"
                    name="material.material_amt_17"
                    onChange={handleChange}
                    value={values.material.material_amt_17}
                  />
                </div>
              )}
              {values.material.material_18 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 18"
                    name="material.material_18"
                    onChange={handleChange}
                    value={values.material.material_18}
                  />
                  <FormField
                    label="Material Amount 18"
                    name="material.material_amt_18"
                    onChange={handleChange}
                    value={values.material.material_amt_18}
                  />
                </div>
              )}
              {values.material.material_19 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 19"
                    name="material.material_19"
                    onChange={handleChange}
                    value={values.material.material_19}
                  />
                  <FormField
                    label="Material Amount 19"
                    name="material.material_amt_19"
                    onChange={handleChange}
                    value={values.material.material_amt_19}
                  />
                </div>
              )}
              {values.material.material_20 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 20"
                    name="material_20"
                    onChange={handleChange}
                    value={values.material.material_20}
                  />
                  <FormField
                    label="Material Amount 20"
                    name="material.material_amt_20"
                    onChange={handleChange}
                    value={values.material.material_amt_20}
                  />
                </div>
              )}
              {values.material.material_21 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 21"
                    name="material_21"
                    onChange={handleChange}
                    value={values.material.material_21}
                  />
                  <FormField
                    label="Material Amount 21"
                    name="material.material_amt_21"
                    onChange={handleChange}
                    value={values.material.material_amt_21}
                  />
                </div>
              )}
              {values.material.material_22 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 22"
                    name="material.material_22"
                    onChange={handleChange}
                    value={values.material.material_22}
                  />
                  <FormField
                    label="Material Amount 22"
                    name="material.material_amt_22"
                    onChange={handleChange}
                    value={values.material.material_amt_22}
                  />
                </div>
              )}
              {values.material.material_23 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 23"
                    name="material.material_23"
                    onChange={handleChange}
                    value={values.material.material_23}
                  />
                  <FormField
                    label="Material Amount 23"
                    name="material.material_amt_23"
                    onChange={handleChange}
                    value={values.material.material_amt_23}
                  />
                </div>
              )}
              {values.material.material_24 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 24"
                    name="material.material_24"
                    onChange={handleChange}
                    value={values.material.material_24}
                  />
                  <FormField
                    label="Material Amount 24"
                    name="material.material_amt_24"
                    onChange={handleChange}
                    value={values.material.material_amt_24}
                  />
                </div>
              )}
              {values.material.material_25 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Material 25"
                    name="material.material_25"
                    onChange={handleChange}
                    value={values.material.material_25}
                  />
                  <FormField
                    label="Material Amount 25"
                    name="material.material_amt_25"
                    onChange={handleChange}
                    value={values.material.material_amt_25}
                  />
                </div>
              )}
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  borderBottom: "1px solid black",
                  paddingBottom: "5px",
                }}
              >
                {"Labors".toUpperCase()}
              </Typography>
              {values.labor.labor_1 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 1"
                    name="labor.labor_1"
                    onChange={handleChange}
                    value={values.labor.labor_1}
                  />
                  <FormField
                    label="Labor Amount 1"
                    name="labor.labor_amt_1"
                    type="number"
                    onChange={handleChange}
                    value={values.labor.labor_amt_1}
                  />
                </div>
              )}
              {values.labor.labor_2 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 2"
                    name="labor.labor_2"
                    type="number"
                    onChange={handleChange}
                    value={values.labor.labor_2}
                  />
                  <FormField
                    label="Labor Amount 2"
                    name="labor.labor_amt_2"
                    type="number"
                    onChange={handleChange}
                    value={values.labor.labor_amt_2}
                  />
                </div>
              )}
              {values.labor.labor_3 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 3"
                    name="labor.labor_3"
                    onChange={handleChange}
                    value={values.labor.labor_3}
                  />
                  <FormField
                    label="Labor Amount 3"
                    name="labor.labor_amt_3"
                    onChange={handleChange}
                    value={values.labor.labor_amt_3}
                  />
                </div>
              )}
              {values.labor.labor_4 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 4"
                    name="labor.labor_4"
                    onChange={handleChange}
                    value={values.labor.labor_4 || ""}
                  />
                  <FormField
                    label="Labor 4 Amount"
                    name="labor.labor_amt_4"
                    onChange={handleChange}
                    value={values.labor.labor_amt_4}
                  />
                </div>
              )}
              {values.labor.labor_5 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 5"
                    name="labor.labor_5"
                    onChange={handleChange}
                    value={values.labor.labor_5 || ""}
                  />
                  <FormField
                    label="Labor 5 Amount"
                    name="labor.labor_amt_5"
                    onChange={handleChange}
                    value={values.labor.labor_amt_5}
                  />
                </div>
              )}
              {values.labor.labor_6 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 6"
                    name="labor.labor_6"
                    onChange={handleChange}
                    value={values.labor.labor_6 || ""}
                  />
                  <FormField
                    label="Labor 6 Amount"
                    name="labor.labor_amt_6"
                    onChange={handleChange}
                    value={values.labor.labor_amt_6}
                  />
                </div>
              )}
              {values.labor.labor_7 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 7"
                    name="labor.labor_7"
                    onChange={handleChange}
                    value={values.labor.labor_7 || ""}
                  />
                  <FormField
                    label="Labor 7 Amount"
                    name="labor.labor_amt_7"
                    onChange={handleChange}
                    value={values.labor.labor_amt_7}
                  />
                </div>
              )}
              {values.labor.labor_8 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 8"
                    name="labor.labor_8"
                    onChange={handleChange}
                    value={values.labor.labor_8 || ""}
                  />
                  <FormField
                    label="Labor 8 Amount"
                    name="labor.labor_amt_8"
                    onChange={handleChange}
                    value={values.labor.labor_amt_8}
                  />
                </div>
              )}
              {values.labor.labor_9 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 1"
                    name="labor.labor_1"
                    onChange={handleChange}
                    value={values.labor.labor_9 || ""}
                  />
                  <FormField
                    label="Labor 9 Amount"
                    name="labor.labor_amt_9"
                    onChange={handleChange}
                    value={values.labor.labor_amt_9}
                  />
                </div>
              )}
              {values.labor.labor_10 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 10"
                    name="labor.labor_10"
                    onChange={handleChange}
                    value={values.labor.labor_10 || ""}
                  />
                  <FormField
                    label="Labor 10 Amount"
                    name="labor.labor_amt_10"
                    onChange={handleChange}
                    value={values.labor.labor_amt_10}
                  />
                </div>
              )}
              {values.labor.labor_11 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 11"
                    name="labor.labor_11"
                    onChange={handleChange}
                    value={values.labor.labor_11 || ""}
                  />
                  <FormField
                    label="Labor 11 Amount"
                    name="labor.labor_amt_11"
                    onChange={handleChange}
                    value={values.labor.labor_amt_11}
                  />
                </div>
              )}
              {values.labor.labor_12 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 12"
                    name="labor.labor_12"
                    onChange={handleChange}
                    value={values.labor.labor_12 || ""}
                  />
                  <FormField
                    label="Labor 12 Amount"
                    name="labor.labor_amt_12"
                    onChange={handleChange}
                    value={values.labor.labor_amt_12}
                  />
                </div>
              )}
              {values.labor.labor_13 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 13"
                    name="labor.labor_13"
                    onChange={handleChange}
                    value={values.labor.labor_13 || ""}
                  />
                  <FormField
                    label="Labor 13 Amount"
                    name="labor.labor_amt_13"
                    onChange={handleChange}
                    value={values.labor.labor_amt_13}
                  />
                </div>
              )}
              {values.labor.labor_14 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 14"
                    name="labor.labor_14"
                    onChange={handleChange}
                    value={values.labor.labor_14 || ""}
                  />
                  <FormField
                    label="Labor 14 Amount"
                    name="labor.labor_amt_14"
                    onChange={handleChange}
                    value={values.labor.labor_amt_14}
                  />
                </div>
              )}
              {values.labor.labor_15 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 15"
                    name="labor.labor_15"
                    onChange={handleChange}
                    value={values.labor.labor_15 || ""}
                  />
                  <FormField
                    label="Labor 15 Amount"
                    name="labor.labor_amt_15"
                    onChange={handleChange}
                    value={values.labor.labor_amt_15}
                  />
                </div>
              )}
              {values.labor.labor_16 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 16"
                    name="labor.labor_16"
                    onChange={handleChange}
                    value={values.labor.labor_16 || ""}
                  />
                  <FormField
                    label="Labor 16 Amount"
                    name="labor.labor_amt_16"
                    onChange={handleChange}
                    value={values.labor.labor_amt_16}
                  />
                </div>
              )}
              {values.labor.labor_17 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 17"
                    name="labor.labor_17"
                    onChange={handleChange}
                    value={values.labor.labor_17 || ""}
                  />
                  <FormField
                    label="Labor 17 Amount"
                    name="labor.labor_amt_17"
                    onChange={handleChange}
                    value={values.labor.labor_amt_17}
                  />
                </div>
              )}
              {values.labor.labor_18 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 18"
                    name="labor.labor_18"
                    onChange={handleChange}
                    value={values.labor.labor_18 || ""}
                  />
                  <FormField
                    label="Labor 18 Amount"
                    name="labor.labor_amt_18"
                    onChange={handleChange}
                    value={values.labor.labor_amt_18}
                  />
                </div>
              )}
              {values.labor.labor_19 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 19"
                    name="labor.labor_19"
                    onChange={handleChange}
                    value={values.labor.labor_19 || ""}
                  />
                  <FormField
                    label="Labor 19 Amount"
                    name="labor.labor_amt_19"
                    onChange={handleChange}
                    value={values.labor.labor_amt_19}
                  />
                </div>
              )}
              {values.labor.labor_20 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 20"
                    name="labor.labor_20"
                    onChange={handleChange}
                    value={values.labor.labor_20 || ""}
                  />
                  <FormField
                    label="Labor 1 Amount"
                    name="labor.labor_amt_20"
                    onChange={handleChange}
                    value={values.labor.labor_amt_20}
                  />
                </div>
              )}
              {values.labor.labor_21 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 21"
                    name="labor.labor_21"
                    onChange={handleChange}
                    value={values.labor.labor_21 || ""}
                  />
                  <FormField
                    label="Labor 21 Amount"
                    name="labor.labor_amt_21"
                    onChange={handleChange}
                    value={values.labor.labor_amt_21}
                  />
                </div>
              )}
              {values.labor.labor_22 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 22"
                    name="labor.labor_22"
                    onChange={handleChange}
                    value={values.labor.labor_22 || ""}
                  />
                  <FormField
                    label="Labor 22 Amount"
                    name="labor.labor_amt_22"
                    onChange={handleChange}
                    value={values.labor.labor_amt_22}
                  />
                </div>
              )}
              {values.labor.labor_23 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 23"
                    name="labor.labor_23"
                    onChange={handleChange}
                    value={values.labor.labor_23 || ""}
                  />
                  <FormField
                    label="Labor 23 Amount"
                    name="labor.labor_amt_23"
                    onChange={handleChange}
                    value={values.labor.labor_amt_23}
                  />
                </div>
              )}
              {values.labor.labor_24 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 24"
                    name="labor.labor_24"
                    onChange={handleChange}
                    value={values.labor.labor_24 || ""}
                  />
                  <FormField
                    label="Labor 24 Amount"
                    name="labor.labor_amt_24"
                    onChange={handleChange}
                    value={values.labor.labor_amt_24}
                  />
                </div>
              )}
              {values.labor.labor_25 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 25"
                    name="labor.labor_25"
                    onChange={handleChange}
                    value={values.labor.labor_25 || ""}
                  />
                  <FormField
                    label="Labor 25 Amount"
                    name="labor.labor_amt_25"
                    onChange={handleChange}
                    value={values.labor.labor_amt_25}
                  />
                </div>
              )}
            </Grid>
          </Box>
          <Box sx={{ textAlign: "end", mt: 1.5 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Apply</Button>
          </Box>
        </form>
      </UpdateIndex>
    </>
  );
};

UpdateOpenInvoice.defaultProps = {
  withHeadingLine: true,
  form: {
    id: "",
    hoc_inv_num: "",
    con: "",
    job_number: "",
    mem_name: "",
    created_at: "",
    mem_street: "",
    remarks: "",
    amount: "",
    updated_amount: "",
    inv_total_labor: "",
    inv_total_material: "",
    inv_net_sale: "",
    rate: "",
    tax: "",
    material_1: "",
    material_amt_1: "",
    material_2: "",
    material_amt_2: "",
    material_3: "",
    material_amt_3: "",
    material_4: "",
    material_amt_4: "",
    material_5: "",
    material_amt_5: "",
    material_6: "",
    material_amt_6: "",
    material_7: "",
    material_amt_7: "",
    material_8: "",
    material_amt_8: "",
    material_9: "",
    material_amt_9: "",
    material_10: "",
    material_amt_10: "",
    material_11: "",
    material_amt_11: "",
    material_12: "",
    material_amt_12: "",
    material_13: "",
    material_amt_13: "",
    material_14: "",
    material_amt_14: "",
    material_15: "",
    material_amt_15: "",
    material_16: "",
    material_amt_16: "",
    material_17: "",
    material_amt_17: "",
    material_18: "",
    material_amt_18: "",
    material_19: "",
    material_amt_19: "",
    material_20: "",
    material_amt_20: "",
    material_21: "",
    material_amt_21: "",
    material_22: "",
    material_amt_22: "",
    material_23: "",
    material_amt_23: "",
    material_24: "",
    material_amt_24: "",
    material_25: "",
    material_amt_25: "",
    labor_1: "",
    labor_amt_1: "",
    labor_2: "",
    labor_amt_2: "",
    labor_3: "",
    labor_amt_3: "",
    labor_4: "",
    labor_amt_4: "",
    labor_5: "",
    labor_amt_5: "",
    labor_6: "",
    labor_amt_6: "",
    labor_7: "",
    labor_amt_7: "",
    labor_8: "",
    labor_amt_8: "",
    labor_9: "",
    labor_amt_9: "",
    labor_10: "",
    labor_amt_10: "",
    labor_1: "",
    labor_amt_1: "",
    labor_11: "",
    labor_amt_11: "",
    labor_12: "",
    labor_amt_12: "",
    labor_13: "",
    labor_amt_13: "",
    labor_14: "",
    labor_amt_14: "",
    labor_15: "",
    labor_amt_15: "",
    labor_16: "",
    labor_amt_16: "",
    labor_17: "",
    labor_amt_17: "",
    labor_18: "",
    labor_amt_18: "",
    labor_19: "",
    labor_amt_19: "",
    labor_20: "",
    labor_amt_20: "",
    labor_21: "",
    labor_amt_21: "",
    labor_22: "",
    labor_amt_22: "",
    labor_23: "",
    labor_amt_23: "",
    labor_24: "",
    labor_amt_24: "",
    labor_25: "",
    labor_amt_25: "",
  },
};
export default UpdateOpenInvoice;
