import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useInvoices from "hooks/useInvoices";
import UpdateCancleInvoice from "pages/App/Invoices/Index/components/UpdateCancelInvoice";
import { editJobNumber, getInvoicedata, updateInvoicedata } from "api/invoices";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { changeStatus } from "api/contractors";
import { DataGrid } from "@mui/x-data-grid";

const OpenContractorInvoiceColumnData = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [, setStatuses] = useState({});
  const [getInvoiceData, setGetInvoiceData] = useState(null);
  const {
    invoicesReducer: { openInvoiceContractor, query, loading, meta },
    getCancleInvoiceContractors,
    changePage,
    changePerPage,
  } = useInvoices();

  useEffect(() => {
    getCancleInvoiceContractors(query);
  }, []);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getCancleInvoiceContractors({
      search: query,
      page: newPage + 1,
      per_page: meta ? meta.per_page : 10,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getCancleInvoiceContractors({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const contractorResults = openInvoiceContractor?.map((contractorData) => {  
    return {  
     id: contractorData?.id,  
     name: contractorData?.contractor_data.name,  
     total: contractorData?.total,  
     created_at: contractorData?.created_at,  
     job_number: contractorData?.job_number,  
     hoc_inv_num: contractorData?.hoc_inv_num,  
     mem_street: contractorData?.mem_street,  
     mem_name: contractorData?.mem_name,  
     mem_email: contractorData?.mem_email,  
     status: contractorData?.status,  
    };  
  });

  const handleClick = (data) => {
    const mailtoUrl = `mailto:${data}`;
    window.open(mailtoUrl, "_blank");
  };

  const editInvoiceHandler = async (id) => {
    try {
      const response = await getInvoicedata(id);
      if (response.data) {
        setGetInvoiceData(response.data);
        setOpen(true);
      }
    } catch (error) {
      console.error("Error retrieving invoice:", error);
    } finally {
      // navigate("/app/invoices");
    }
  };

  const onSubmit = async (values) => {
    try {
      const response = await updateInvoicedata(values.id, values);
      if (response.data) {
        Swal.fire({
          title: "Are you sure?",
          text: "You would like to make these changes to the Cancel invoice?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            title: "swal-text",
            popup: "swal-text",
          },
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              toast: true,
              timer: 4000,
              position: "top-right",
              title: "Cancel Contractor Invoice Updated Successfully!",
              showConfirmButton: false,
              icon: "success",
              timerProgressBar: true,
            }).then(() => {
              navigate("/app/invoices");
            });
          }
        });
      }
      getCancleInvoiceContractors(query);
    } catch (error) {
      Swal.fire({
        toast: true,
        position: "top-right",
        icon: "error",
        title: "Something went wrong!",
        timer: 4000,
        showConfirmButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/invoices");
        }
      });
    }
  };

  const handleCellEditCommit = async (newRow) => {
    const newJobNumber = newRow.value;
    const conInvId = newRow.id;
    const data = {
      job_number: newJobNumber,
      con_inv_id: conInvId,
    };
    try {
      const response = await editJobNumber(data);
      if (response.data) {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response.data.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Error updating job number:", error);
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: "Error Updating Job Number",
        showConfirmButton: false,
        icon: "error",
        timerProgressBar: true,
      });
    }
  };

  const renderAction = (data) => (
    <div>
      <Button
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() =>
          navigate(`/app/viewInvContractorHoc/${data?.id}/${data.id}`)
        }
      >
        View
      </Button>
      <Button
        sx={{ ml: 2 }}
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() => editInvoiceHandler(data?.id)}
      >
        Edit
      </Button>
      <Button
        sx={{ ml: 2 }}
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() => handleClick(data.row.mem_email)}
      >
        Email
      </Button>
    </div>
  );

  const OpenInvoiceLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/invoices/${params.id}`;
    const handleLinkClick = (event) => {
      event.preventDefault();
      navigate(url, { replace: true });
    };
    return (
      <a href="#" onClick={handleLinkClick} className="link">
        {params.value}
      </a>
    );
  };

  const handleChange = (e, id) => {
    const newStatus = e.target.value;
    setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: newStatus }));
    changeStatus(id, newStatus)
      .then((response) => {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response?.data?.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
        getCancleInvoiceContractors(query);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          position: "top-right",
          timer: 4000,
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
        });
      });
  };

  const columns = (renderAction, renderEditAction) => [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      type: "string",
      width: 60,
      renderCell: (params) => <OpenInvoiceLink params={params} />,
    },
    {
      field: "name",
      headerName: "Contractor name",
      width: 240,
    },
    {
      field: "mem_name",
      headerName: "Member Name",
      width: 150,
    },
    {
      field: "hoc_inv_num",
      headerName: "Invoice Number",
      width: 140,
    },
    {
      field: "job_number",
      headerName: "Job Number",
      width: 110,
      editable: true,
      renderCell: (params) => (
        <Tooltip title="Click to edit job number">
          <div style={{ cursor: "pointer" }}>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "mem_street",
      headerName: "Member Address",
      width: 200,
    },
    {
      field: "total",
      headerName: "Total Price",
      width: 100,
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 100,
      renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      editable: true,
      sortable: true,
      width: 190,
      renderCell: (params) => {
        const status = params.value;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select
              value={status}
              onChange={(e) => handleChange(e, params.row.id)}
              sx={{ width: "100%" }}
            >
              <MenuItem value="RECEIVED">RECEIVED</MenuItem>
              <MenuItem value="PENDING">PENDING</MenuItem>
              <MenuItem value="HOLD">HOLD</MenuItem>
              <MenuItem value="APPROVED">APPROVED</MenuItem>
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="NOT APPROVED">NOT APPROVED</MenuItem>
            </Select>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      type: "string",
      sortable: false,
      width: 270,
      renderCell: renderAction,
    },
  ];

  return (
    <>
      {open && (
        <UpdateCancleInvoice
          open={open}
          handleClose={() => setOpen(false)}
          onSubmitHandler={onSubmit}
          form={getInvoiceData}
        />
      )}
      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <>
          <DataGrid
            columns={columns(renderAction)}
            rows={contractorResults ?? []}
            pageSize={Number(meta?.per_page)}
            rowsPerPageOptions={[Number(meta?.per_page)]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
            onCellEditCommit={handleCellEditCommit}
          />
        </>
      )}
    </>
  );
};

export default OpenContractorInvoiceColumnData;
